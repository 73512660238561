export const NavData = [
    {
        title: 'About Me',
        link: 'about'
    },
    {
        title: 'Projects',
        link: 'projects'
    },
    {
        title: 'Skills',
        link: 'skills',
    },
    {
        title: 'Education',
        link: 'education'
    },
    {
        title: 'Experience',
        link: 'experience'
    }
]